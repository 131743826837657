import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  FileDataDisplay,
  type FileDataDisplayProps,
} from "#src/components/DataDisplay/FileDataDisplay";
import {
  GeocoordinateDataDisplay,
  type GeocoordinateDataDisplayProps,
} from "#src/components/DataDisplay/GeocoordinateDataDisplay";
import { DefaultLocalizationMapping } from "#src/hooks/LocalizationProvider";
import useLocalization from "#src/hooks/useLocalization";
import {
  linkToDeviceDetail,
  linkToEquipmentDetail,
  linkToFacilities,
} from "#src/Routers/links";
import { FlowDetailRoute } from "#src/routes/organization/flows/[flowId]/detail";
import { NetworkDetailRoute } from "#src/routes/organization/networks/[networkId]/detail";
import {
  ArrayDataDisplay,
  BooleanDataDisplay,
  DateDataDisplay,
  DateRangeDataDisplay,
  IntegerDataDisplay,
  NumberDataDisplay,
  TextDataDisplay,
  type ArrayDataDisplayProps,
  type BooleanDataDisplayProps,
  type DataDisplayProps,
  type DateDataDisplayProps,
  type DateRangeDataDisplayProps,
  type IntegerDataDisplayProps,
  type NumberDataDisplayProps,
  type TextDataDisplayProps,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  DateSchema,
  DateTimeRangeSchema,
  DateTimeSchema,
  GeocoordinateSchema,
  FileUploadedSchema,
  type AttributeDataTypeType,
  type ResourceLookupType,
  type FileUploadedType,
} from "@validereinc/domain";
import isPlainObject from "lodash/isPlainObject";
import React from "react";
import { z } from "zod";

export type DataTypeToDataDisplayProps = {
  [K in AttributeDataTypeType]: K extends "boolean"
    ? Omit<BooleanDataDisplayProps, "value">
    : K extends "number"
      ? Omit<NumberDataDisplayProps, "value">
      : K extends "integer"
        ? Omit<IntegerDataDisplayProps, "value">
        : K extends "multi-pick-list" | "number-array"
          ? Omit<ArrayDataDisplayProps, "value">
          : K extends "file"
            ? Omit<FileDataDisplayProps, "value">
            : K extends "geo_point"
              ? Omit<GeocoordinateDataDisplayProps, "value">
              : K extends "date" | "date-time"
                ? Omit<DateDataDisplayProps, "value">
                : K extends "date-time-range"
                  ? Omit<DateRangeDataDisplayProps, "value">
                  : Omit<TextDataDisplayProps, "value">;
};

/**
 * Display a value using the data type definition describing the type of the value
 * @param value the value to render
 * @param dataType the type of the value @see {@link AttributeDataTypeType}
 * @param opts additional display options shared among all renderers, or that are specific to one renderer
 * @returns renders the value using the appropriate renderer for the provided type
 */
export const displayValueByDataType = <T extends AttributeDataTypeType>(
  value: any,
  dataType: T,
  opts?: DataTypeToDataDisplayProps[T]
) => {
  switch (dataType) {
    case "boolean": {
      return (
        <BooleanDataDisplay
          value={value}
          {...(opts ?? {})}
        />
      );
    }
    case "multi-pick-list":
      return (
        <ArrayDataDisplay
          value={value ?? []}
          {...(opts ?? {})}
        />
      );
    case "file": {
      const { name, ref } = value as FileUploadedType;

      return (
        <FileDataDisplay
          fileName={name}
          fileId={ref}
          {...(opts ?? {})}
        />
      );
    }
    case "number":
      return (
        <NumberDataDisplay
          value={value}
          {...(opts ?? {})}
        />
      );
    case "integer":
      return (
        <IntegerDataDisplay
          value={value}
          {...(opts ?? {})}
        />
      );
    case "geo_point":
      return <GeocoordinateDataDisplay coordinates={value} />;
    case "lookup": {
      if (!value?.id) {
        return value?.name ?? opts?.emptyText ?? null;
      }

      return (
        getLookupLink(
          value?.id,
          value?.name,
          value?.lookup_entity_type ?? value?.entity_type
        ) ??
        opts?.emptyText ??
        null
      );
    }
    case "date":
      return (
        <DateDataDisplay
          value={value}
          convertToUTC
          {...(opts ?? {})}
        />
      );
    case "date-time":
      return (
        <DateDataDisplay
          // assumption that value is in UTC time
          value={value}
          convertToUTC={false}
          {...(opts ?? {})}
        />
      );
    case "date-time-range": {
      if (!DateTimeRangeSchema.optional().nullable().safeParse(value).success)
        return opts?.emptyText ?? null;

      return (
        <DateRangeDataDisplay
          value={value ? { from: value[0], to: value[1] } : value}
          convertToUTC
          {...(opts ?? {})}
        />
      );
    }
    case "number-array": {
      if (!Array.isArray(value)) return opts?.emptyText ?? null;

      return (
        <ArrayDataDisplay
          value={value}
          {...(opts ?? {})}
        />
      );
    }
    case "string":
    case "pick-list":
    default:
      return (
        <TextDataDisplay
          value={value}
          clamp={2}
          style={{ width: "100%" }}
          {...(opts ?? {})}
        />
      );
  }
};

/**
 * Display a value by attempting to parse the data type of the value. Note, this
 * may not be as reliable as explicitly specifying the data type and calling
 * {@link displayValueByDataType}
 * @param value the value to render
 * @param opts additional display options shared among all renderers, or that
 * are specific to one renderer
 * @returns renders the value using the appropriate renderer for the determined
 * data type
 */
export const displayValueWithUnknownDataType = (
  value: unknown,
  opts?: Omit<DataDisplayProps, "value"> & { strict?: boolean }
) => {
  if (
    (!opts?.strict && z.boolean().safeParse(value).success) ||
    (opts?.strict && typeof value === "boolean")
  ) {
    return (
      <BooleanDataDisplay
        value={value as boolean}
        {...(opts ?? {})}
      />
    );
  }

  if (!opts?.strict && z.number().int().safeParse(Number(value)).success) {
    return (
      <IntegerDataDisplay
        alignment="right"
        value={value as number}
        {...(opts ?? {})}
      />
    );
  }

  if (
    (!opts?.strict && z.number().safeParse(Number(value)).success) ||
    (opts?.strict && typeof value === "number")
  ) {
    return (
      <NumberDataDisplay
        alignment="right"
        value={value as number}
        {...(opts ?? {})}
      />
    );
  }

  if (Array.isArray(value)) {
    if (GeocoordinateSchema.safeParse(value).success) {
      return (
        <GeocoordinateDataDisplay
          coordinates={value as [number | null, number | null]}
          {...(opts ?? {})}
        />
      );
    }

    if (DateTimeRangeSchema.safeParse(value).success) {
      return (
        <DateRangeDataDisplay
          value={{ from: value[0], to: value[1] }}
          {...(opts ?? {})}
        />
      );
    }

    return (
      <ArrayDataDisplay
        value={value}
        {...(opts ?? {})}
      />
    );
  }

  if (isPlainObject(value)) {
    if (FileUploadedSchema.safeParse(value).success) {
      return (
        <FileDataDisplay
          fileName={(value as FileUploadedType).name}
          fileId={(value as FileUploadedType).ref}
          {...(opts ?? {})}
        />
      );
    }
  }

  if (DateTimeSchema.safeParse(value).success) {
    return (
      <DateDataDisplay
        value={value as string}
        {...(opts ?? {})}
        convertToUTC
      />
    );
  }

  if (DateSchema.safeParse(value).success) {
    return (
      <DateDataDisplay
        value={value as string}
        {...(opts ?? {})}
      />
    );
  }

  return value ?? opts?.emptyText ?? null;
};

export const getLookupLink = (
  id: string,
  name: string,
  resourceType: ResourceLookupType
) => {
  switch (resourceType) {
    case AssetType.FACILITY:
      return <RoutingLink to={linkToFacilities(id)}>{name}</RoutingLink>;
    case AssetType.EQUIPMENT:
      return <RoutingLink to={linkToEquipmentDetail(id)}>{name}</RoutingLink>;
    case AssetType.FLOW:
      return (
        <RoutingLink
          to={FlowDetailRoute.toLink({
            pathParams: { flowId: id },
          })}
        >
          {name}
        </RoutingLink>
      );
    case AssetType.DEVICE:
      return <RoutingLink to={linkToDeviceDetail(id)}>{name}</RoutingLink>;
    case AssetType.ASSET_GROUP:
      return (
        <RoutingLink
          to={NetworkDetailRoute.toLink({
            pathParams: { networkId: id },
          })}
        >
          {name}
        </RoutingLink>
      );
    default:
      return name;
  }
};

/**
 * A function that returns the localized string of an asset type.
 * @param assetType the asset type to localize.
 * @returns a localized string representing an asset type.
 */
export const displayLocalizedAssetType = (assetType?: AssetTypeType) => {
  const { localize } = useLocalization();
  switch (assetType) {
    case AssetType.FACILITY:
      return localize("facility_plural");
    case AssetType.EQUIPMENT:
      return localize("equipment_plural");
    case AssetType.DEVICE:
      return localize("device_plural");
    case AssetType.FLOW:
      return localize("flow_plural");
    case AssetType.ASSET_GROUP:
      return localize("asset_group_plural");
    default:
      return localize("asset_plural");
  }
};

export const displayPluralizedAssetType = (assetType?: AssetTypeType) => {
  switch (assetType) {
    case AssetType.FACILITY:
      return DefaultLocalizationMapping.facility_plural;
    case AssetType.EQUIPMENT:
      return DefaultLocalizationMapping.equipment_plural;
    case AssetType.DEVICE:
      return DefaultLocalizationMapping.device_plural;
    case AssetType.FLOW:
      return DefaultLocalizationMapping.flow_plural;
    case AssetType.ASSET_GROUP:
      return DefaultLocalizationMapping.asset_group_plural;
    default:
      return DefaultLocalizationMapping.asset_plural;
  }
};
