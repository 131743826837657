import { z } from "zod";
import { FilterObjectType, SortDirection } from "../util";
import { AssetTypeType } from "./AssetSchemas";

export const DefaultRecordValueConfigurationFunction = {
  SUM: "sum",
  AVG: "avg",
  TOP: "top",
  TIME_WEIGHTED_AVG: "time_weighted_avg",
} as const;

export const DefaultRecordValueConfigurationInterpolationMethod = {
  FORWARD_FILL: "forward_fill",
} as const;

export const DefaultRecordValueConfigurationInterpolationInterval = {
  DAY: "day",
  HOUR: "hour",
} as const;

export const DefaultAggregationSort = {
  FIRST_SORT: "measurement.time",
  SECOND_SORT: "measurement.created_at",
  SORT_DIRECTION: "desc",
};

/**
The only use case we have for Device DRVC has the following filter set for searching measurements from measurement series. As we expand use cases for DRVC from measurement series, the start and end date will need to be user defined
*/
export const DefaultDeviceDRVCFilter = {
  INTERVAL: "1day",
  FUNC: "sum",
  START: "2019-01-01",
  END: "2026-12-31",
};

export type DefaultRecordValueConfigurationFunctionType =
  (typeof DefaultRecordValueConfigurationFunction)[keyof typeof DefaultRecordValueConfigurationFunction];

export const DefaultRecordValueConfigurationType = {
  FORM_MEASUREMENT: "form_measurement",
  CALCULATOR_RESULT: "calculator_result",
  MEASUREMENT_SERIES: "measurement_series",
  RECORD: "record",
} as const;

export type DefaultRecordValueConfigurationTypeType =
  (typeof DefaultRecordValueConfigurationType)[keyof typeof DefaultRecordValueConfigurationType];

export const DefaultRecordValueConfigurationTypeNames = {
  [DefaultRecordValueConfigurationType.CALCULATOR_RESULT]:
    "Specific Estimation Method",
  [DefaultRecordValueConfigurationType.FORM_MEASUREMENT]: "Form Template",
  [DefaultRecordValueConfigurationType.MEASUREMENT_SERIES]:
    "Device Measurements",
  [DefaultRecordValueConfigurationType.RECORD]: "Records",
};

export const DefaultRecordValueConfigurationFunctionNames = {
  [DefaultRecordValueConfigurationFunction.TOP]: "Most Recent",
  [DefaultRecordValueConfigurationFunction.AVG]: "Average",
  [DefaultRecordValueConfigurationFunction.SUM]: "Sum",
  [DefaultRecordValueConfigurationFunction.TIME_WEIGHTED_AVG]:
    "Time Weighted Average",
};

export const DefaultRecordValueConfigurationInterpolationIntervalNames = {
  [DefaultRecordValueConfigurationInterpolationInterval.DAY]: "Daily",
  [DefaultRecordValueConfigurationInterpolationInterval.HOUR]: "Hourly",
};

export type DefaultRecordValueConfigurationFilterType = FilterObjectType<{
  id?: string;
  measurement_type?: string;
  measurement_unit?: string;
  asset_id?: string;
  asset_type?: AssetTypeType;
  reporting_group_id?: string;
  reporting_group_name?: string;
}>;

export const DefaultRecordValueConfigurationFormMeasurementSchema = z.object({
  configuration_type: z.literal(
    DefaultRecordValueConfigurationType.FORM_MEASUREMENT
  ),
  aggregate_function: z.nativeEnum(DefaultRecordValueConfigurationFunction),
  sort: z.string(),
  sort_direction: z.nativeEnum(SortDirection),
  second_sort: z.string(),
  filter: z.object({}),
  timezone: z.string().optional(),
  window: z.string().optional(),
  time_weighted_interpolation_method: z
    .nativeEnum(DefaultRecordValueConfigurationInterpolationMethod)
    .optional(),
  time_weighted_interpolation_interval: z
    .nativeEnum(DefaultRecordValueConfigurationInterpolationInterval)
    .optional(),
  use_measurement_weighted_values: z.boolean().optional(),
  measurement_weighted_values_filter: z.object({}),
});

export const DefaultRecordValueConfigurationCalculatorResultSchema = z.object({
  configuration_type: z.literal(
    DefaultRecordValueConfigurationType.CALCULATOR_RESULT
  ),
  aggregate_function: z.nativeEnum(DefaultRecordValueConfigurationFunction),
  sort: z.string(),
  sort_direction: z.nativeEnum(SortDirection),
  filter: z.object({}),
  timezone: z.string().optional(),
  window: z.string().optional(),
});

export const DefaultRecordValueConfigurationMeasurementSeriesSchema = z.object({
  configuration_type: z.literal(
    DefaultRecordValueConfigurationType.MEASUREMENT_SERIES
  ),
  aggregate_function: z.nativeEnum(DefaultRecordValueConfigurationFunction),
  filter: z.object({}),
  timezone: z.string().optional(),
  window: z.string().optional(),
});

export const DefaultRecordValueConfigurationRecordsSchema = z.object({
  configuration_type: z.literal(DefaultRecordValueConfigurationType.RECORD),
  aggregate_function: z.nativeEnum(DefaultRecordValueConfigurationFunction),
  sort: z.string(),
  sort_direction: z.nativeEnum(SortDirection),
  filter: z.object({}),
  timezone: z.string().optional(),
  window: z.string().optional(),
});

export type DefaultRecordValueConfigurationFormMeasurementType = z.infer<
  typeof DefaultRecordValueConfigurationFormMeasurementSchema
>;
export type DefaultRecordValueConfigurationCalculatorResultType = z.infer<
  typeof DefaultRecordValueConfigurationCalculatorResultSchema
>;
export type DefaultRecordValueConfigurationMeasurementSeriesType = z.infer<
  typeof DefaultRecordValueConfigurationMeasurementSeriesSchema
>;

export type DefaultRecordValueConfigurationRecordsType = z.infer<
  typeof DefaultRecordValueConfigurationRecordsSchema
>;

export const DefaultRecordValueConfigurationConfigurationSchema =
  z.discriminatedUnion("configuration_type", [
    DefaultRecordValueConfigurationFormMeasurementSchema,
    DefaultRecordValueConfigurationCalculatorResultSchema,
    DefaultRecordValueConfigurationMeasurementSeriesSchema,
    DefaultRecordValueConfigurationRecordsSchema,
  ]);

export type DefaultRecordValueConfigurationConfigurationType = z.infer<
  typeof DefaultRecordValueConfigurationConfigurationSchema
>;

export const DefaultRecordValueConfigurationSchema = z.object({
  asset_id: z.string(),
  reporting_group_id: z.string(),
  measurement_type: z.string(),
  measurement_unit: z.string(),
  default_value: z.number().nullable(),
  configuration: DefaultRecordValueConfigurationConfigurationSchema,
});

export type DefaultRecordValueConfigurationType = z.infer<
  typeof DefaultRecordValueConfigurationSchema
>;
